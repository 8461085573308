<template>
  <div class="particle-container">
    <Particles
      id="tsparticles"
      class="particle-background"
      :options="{
        fullScreen: true,
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: 'push',
            },
            onHover: {
              enable: true,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 100,
              duration: 10,
              size: 6,
            },
            attract: {
              easing: 'ease-out-quad',
              radius: 20,
            },
            slow: {
              factor: 1,
              easing: 'ease-out-quad',
              radius: 100,
            },
            push: {
              random: true,
              quantity: 5,
            },
            repulse: {
              distance: 20,
              duration: 0.4,
              factor: 1,
            },
          },
        },

        particles: {
          color: {
            value: '#ffffff',
          },
          links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 1,
            straight: true,
          },
          number: {
            density: {
              enable: true,
              value_area: 600,
            },
            value: 50,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 3,
          },
        },
        detectRetina: true,
      }"
    />
    <!-- <button @click="changeColor">Change color</button> -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ParticleBackground",
  data() {
    return {
      colors: ["#89C4C2", "#BC789E", "#F1705F", "#80ADE1"],
      count: 0,
      timer: null,
    };
  },
  computed: {
    index() {
      return Math.floor(Math.random() * this.colors.length) + this.count;
    },
    primaryColor() {
      return this.colors[this.index % this.colors.length];
    },
  },
  methods: {
    changeColor() {
      this.count = this.count + 1;
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.changeColor();
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
#tsparticles canvas {
  z-index: -1 !important;
  background: v-bind(primaryColor);
  transition: background 2000ms ease-in;
}
</style>
