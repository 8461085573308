<template>
  <div id="my-home">
    <app-header />
    <div class="content">
      <main>
      </main>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";

export default {
  name: "Home",
  components: {
    AppHeader,
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: rgba(whitesmoke, 0.9);
  color: #120502;
  padding: 1em;
  min-height: 50vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main {
  max-width: 800px;
  margin: auto auto;
}
</style>
