<template>
  <div id="app-header">
    <particle-background>
      <div class="particle-overlay">
        <h1 :key="title" class="unselectable">{{ title }}</h1>
        <!-- <button @click="changeIndex">Index</button> -->
      </div>
    </particle-background>
  </div>
</template>

<script>
import ParticleBackground from "./ParticleBackground.vue";

export default {
  name: "AppHeader",
  components: {
    ParticleBackground,
  },
  data() {
    return {
      titles: ["Hello world.", "B", "C"],
      index: 0,
    };
  },
  computed: {
    title() {
      return this.titles[this.index % this.titles.length];
    },
  },
  methods: {
    changeIndex() {
      this.index += 1;
    },
  },
};
</script>

<style lang="scss">
#app-header {
  color: whitesmoke;
  height: 50vh;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .particle-overlay {
    padding: 1em;
    text-align: center;
  }

  .particle-background {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
